import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";

import { MdDragHandle, MdClose } from "react-icons/md";

export default function CreateBlogModal(props) {
  const [imgCount, setImgCount] = useState(1);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [thumbnail, setThumbnail] = useState();
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");
  const [img5, setImg5] = useState("");
  const [img6, setImg6] = useState("");
  const [img7, setImg7] = useState("");
  const [img8, setImg8] = useState("");
  const [img9, setImg9] = useState("");
  const [img10, setImg10] = useState("");

  const newImgData = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
  ];

  const { isOpen, username, handleClose, handleCallback } = props;

  const date = new Date();

  const result = date.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const removeImg = (imgCount) => {
    if (imgCount === 2) {
      setImg2("");
    }
    if (imgCount === 3) {
      setImg3("");
    }
    if (imgCount === 4) {
      setImg4("");
    }
    if (imgCount === 5) {
      setImg5("");
    }
    if (imgCount === 6) {
      setImg6("");
    }
    if (imgCount === 7) {
      setImg7("");
    }
    if (imgCount === 8) {
      setImg8("");
    }
    if (imgCount === 9) {
      setImg9("");
    }
    if (imgCount === 10) {
      setImg10("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const filteredImg = newImgData.filter((str) => str !== "");

    const formData = new FormData();
    formData.append("file", thumbnail);

    axios
      .post(`https://data.galileo-tutor.com/blog/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {});

    if (img1 != "") {
      const formData = new FormData();
      formData.append("file", img1);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload1`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img2 != "") {
      const formData = new FormData();
      formData.append("file", img2);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload2`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img3 != "") {
      const formData = new FormData();
      formData.append("file", img3);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload3`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img4 != "") {
      const formData = new FormData();
      formData.append("file", img4);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload4`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img5 != "") {
      const formData = new FormData();
      formData.append("file", img5);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload5`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img6 != "") {
      const formData = new FormData();
      formData.append("file", img6);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload6`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img7 != "") {
      const formData = new FormData();
      formData.append("file", img7);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload7`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img8 != "") {
      const formData = new FormData();
      formData.append("file", img8);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload8`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img9 != "") {
      const formData = new FormData();
      formData.append("file", img9);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload9`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }
    if (img10 != "") {
      const formData = new FormData();
      formData.append("file", img10);

      axios
        .post(`https://data.galileo-tutor.com/blog/upload10`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {});
    }

    const eventObject = {
      title: title,
      description: desc,
      date: result,
      datetime: "",
      imgUrl: [],
      author: {
        name: "ครูเติม",
        role: "ผู้บริหาร / ติวเตอร์",
        href: "#",
        imageUrl: "term.jpg ",
      },
      thumbnail: thumbnail,
    };

    axios
      .post(`https://data.galileo-tutor.com/blog/create-blog`, eventObject)
      .then((res) => console.log(res.data));

    setTitle("");
    setDesc("");
    setThumbnail(null);

    handleClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0  overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="min-w-screen-lg relative transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                {/* Main Content */}

                <div className="rounded-2xl bg-white">
                  <header className="flex items-center justify-between rounded-t-2xl bg-gray-100 px-4 py-2">
                    <MdDragHandle className="text-2xl text-gray-400" />
                    <p className="text-xl font-bold text-gray-800">
                      เพิ่มบทความ
                    </p>
                    <div>
                      <button>
                        <MdClose
                          className="text-2xl text-gray-400"
                          onClick={handleClose}
                        />
                      </button>
                    </div>
                  </header>
                  <div className="p-8">
                    <label className="text-xl font-bold">
                      ชื่อบทความ
                    </label>
                    <br />
                    <input
                      type="text"
                      name="title"
                      placeholder=""
                      value={title}
                      required
                      className="mb-2 w-full rounded-xl border border-gray-300 bg-slate-100 px-4  pb-2 pt-3 text-xl text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-0"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <br />
                    <br />
                    <label className="text-xl  font-bold">
                      รายละเอียด
                    </label>
                    <br />
                    <textarea
                      type="text"
                      name="title"
                      placeholder=""
                      value={desc}
                      rows={5}
                      className="mb-2 w-full  rounded-xl border border-gray-300 bg-slate-100 px-4  pb-2 pt-3 text-xl text-gray-900 focus:border-blue-500 focus:outline-none focus:ring-0"
                      onChange={(e) => setDesc(e.target.value)}
                    />
                    <br />
                    <br />
                    <label className="text-xl font-bold">
                      ปกบทความ
                    </label>
                    <br />
                    <input
                      className={`${
                        thumbnail === "" ? null : "bg-slate-100 "
                      } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                      type="file"
                      // value={data.thaiFile}
                      onChange={(e) => {
                        setThumbnail(e.target.files[0]);
                      }}
                      accept="image/png, image/jpeg"
                    />
                    <br />
                    <br />
                    <label className="text-xl font-bold">รูป</label>
                    <br />
                    <input
                      className={`${
                        img1 === "" ? null : "bg-slate-100 "
                      } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                      type="file"
                      // value={data.thaiFile}

                      accept="image/png, image/jpeg"
                      onChange={(e) => {
                        setImg1(e.target.files[0]);
                        console.log(img1);
                      }}
                    />
                    {imgCount >= 2 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img2 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg2(e.target.files[0]);
                            console.log(img2);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 3 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img3 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg3(e.target.files[0]);
                            console.log(img3);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 4 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img4 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg4(e.target.files[0]);
                            console.log(img4);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 5 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img5 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg5(e.target.files[0]);
                            console.log(img5);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 6 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img6 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg6(e.target.files[0]);
                            console.log(img6);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 7 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img7 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg7(e.target.files[0]);
                            console.log(img7);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 8 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img8 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg8(e.target.files[0]);
                            console.log(img8);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 9 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img9 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg9(e.target.files[0]);
                            console.log(img9);
                          }}
                        />
                      </>
                    ) : null}
                    {imgCount >= 10 ? (
                      <>
                        <br />
                        <input
                          className={`${
                            img10 === "" ? null : "bg-slate-100 "
                          } my-2 w-full cursor-pointer rounded-2xl border border-slate-200 p-5 text-xl duration-150 placeholder:text-xl focus:outline-blue-500`}
                          type="file"
                          // value={data.thaiFile}

                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            setImg10(e.target.files[0]);
                            console.log(img10);
                          }}
                        />
                      </>
                    ) : null}
                    <br />

                    {imgCount >= 10 ? null : (
                      <button
                        onClick={() => setImgCount(imgCount + 1)}
                        className={`my-2 mr-2 cursor-pointer rounded-2xl border-2 border-dashed border-slate-200 p-5 text-xl duration-150 hover:bg-gray-100`}
                      >
                        เพิ่มรูป
                      </button>
                    )}

                    {imgCount != 1 ? (
                      <button
                        onClick={() => {
                          setImgCount(imgCount - 1);
                          removeImg();
                        }}
                        className={`my-2 mx-2 cursor-pointer rounded-2xl border-2 border-dashed border-slate-200 p-5 text-xl duration-150 hover:bg-gray-100`}
                      >
                        ลดรูป
                      </button>
                    ) : null}

                    <br />
                    <br />
                    <label className="text-xl  font-bold">
                      วันที่ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="font-normal">{result}</span>
                    </label>
                  </div>
                  <footer className="mt-5 flex justify-end border-t p-3">
                    {title === "" || desc === "" ? (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="cursor-not-allowed rounded-lg bg-gray-400 px-6 py-2 text-white duration-150 ease-in-out "
                        disabled
                      >
                        เพิ่มบทความ
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="rounded-lg bg-gray-800 px-6 py-2 text-white duration-150 ease-in-out hover:bg-gray-900 "
                      >
                        เพิ่มบทความ
                      </button>
                    )}
                  </footer>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
