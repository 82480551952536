import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { AiOutlinePlus } from "react-icons/ai";
import CreateSaveForm from "./CreateSaveForm";

export default function CreateSavedModal(props) {
  const { isOpen, username, handleClose, date, savedStream } = props;

  const [closeLabelModal, setCloseLabelModal] = useState(false);

  const Callback = (childData) => {
    setCloseLabelModal(childData);
  };
  useEffect(() => {
    if (closeLabelModal === true) {
      handleClose();
    }
  }, [closeLabelModal]);

  const [selectedLabel, setSelectedLabel] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const dict = {
    ครูเติม: "เพิ่มตารางสอนครู",
    เวลาว่างครูเติม: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูมีน: "เพิ่มตารางสอนครู",
    เวลาว่างครูมีน: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูกิต: "เพิ่มตารางสอนครู",
    เวลาว่างครูกิต: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูวี: "เพิ่มตารางสอนครู",
    เวลาว่างครูวี: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูพี: "เพิ่มตารางสอนครู",
    เวลาว่างครูพี: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูบิ๊ก: "เพิ่มตารางสอนครู",
    เวลาว่างครูบิ๊ก: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
  };

  const example = [
    {
      user: "admin",
      permission: [
        "ครูเติม",
        "เวลาว่างครูเติม",
        "ครูมีน",
        "เวลาว่างครูมีน",
        "ครูกิต",
        "เวลาว่างครูกิต",
        "ครูวี",
        "เวลาว่างครูวี",
        "ครูพี",
        "เวลาว่างครูพี",
        "ครูบิ๊ก",
        "เวลาว่างครูบิ๊ก",
      ],
    },
    {
      user: "term",
      permission: ["ครูเติม", "เวลาว่างครูเติม"],
    },
    {
      user: "meen",
      permission: ["ครูมีน", "เวลาว่างครูมีน"],
    },
    {
      user: "kit",
      permission: ["ครูกิต", "เวลาว่างครูกิต"],
    },
    {
      user: "wee",
      permission: ["ครูวี", "เวลาว่างครูวี"],
    },
    {
      user: "pea",
      permission: ["ครูพี", "เวลาว่างครูพี"],
    },
    {
      user: "big",
      permission: ["ครูบิ๊ก", "เวลาว่างครูบิ๊ก"],
    },
  ];

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 mt-20 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                  {/* Main Content */}

                  <div className="bg-white rounded-2xl">
                    <header className="bg-gray-100 px-4 py-2 flex justify-between rounded-t-2xl items-center">
                      <span className="material-icons-outlined text-gray-400">
                        drag_handle
                      </span>
                      <p className="font-bold text-xl text-gray-800">
                        เพิ่มบันทึก
                      </p>
                      <button>
                        <span
                          className="material-icons-outlined text-gray-400"
                          onClick={handleClose}
                        >
                          close
                        </span>
                      </button>
                    </header>
                    <div className="p-3">
                      <div className="items-center">
                        <div
                          className={
                            username === "admin"
                              ? `grid grid-cols-4 gap-x-3 gap-y-3`
                              : `grid grid-cols-2 gap-x-3 gap-y-3`
                          }
                        >
                          {example.map((item, idx) => (
                            <>
                              {item.user === username ? (
                                <>
                                  {item.permission.map((label, i) => (
                                    <div
                                      className="text-center cursor-pointer bg-gray-100 py-4 px-4 rounded-xl ease-in-out duration-100 hover:bg-gray-200"
                                      onClick={() => {
                                        setSelectedLabel(label);

                                        setOpenDialog(true);
                                        setCloseLabelModal(false);
                                      }}
                                    >
                                      <div className="flex items-center justify-center">
                                        <span
                                          key={i}
                                          className={`bg-${label}-500 w-10 h-10 rounded-full  flex items-center justify-center cursor-pointer`}
                                        ></span>
                                      </div>

                                      <p className="text-lg mt-1">
                                        {dict[label]}
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <CreateSaveForm
        isOpen={openDialog}
        username={username}
        savedStream={savedStream}
        selectedLabel={selectedLabel}
        handleCallback={Callback}
        date={date}
        handleClose={() => {
          setOpenDialog(false);
          setSelectedLabel("");
        }}
      />
    </>
  );
}
