import React, { useState } from "react";
import Modal from "./Modal";
import { useLocation } from "react-router-dom";
import SavedModal from "./SavedModal";

export default function CreateEventButton(props) {
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  return (
    <>
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
      >
        <span className="pl-3 pr-3">ที่บันทึกไว้</span>
      </button>

      <SavedModal
        isOpen={isOpen}
        savedStream={props.savedStream}
        type=""
        username={location.state.id}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}
