import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Labels(props) {
  const location = useLocation();

  const [userinfo, setUserInfo] = useState({
    languages: [
      "ครูเติม",
      "เวลาว่างครูเติม",
      "ครูมีน",
      "เวลาว่างครูมีน",
      "ครูกิต",
      "เวลาว่างครูกิต",
      "ครูวี",
      "เวลาว่างครูวี",
      "ครูพี",
      "เวลาว่างครูพี",
      "ครูบิ๊ก",
      "เวลาว่างครูบิ๊ก",
    ],
    response: [
      "ครูเติม",
      "เวลาว่างครูเติม",
      "ครูมีน",
      "เวลาว่างครูมีน",
      "ครูกิต",
      "เวลาว่างครูกิต",
      "ครูวี",
      "เวลาว่างครูวี",
      "ครูพี",
      "เวลาว่างครูพี",
      "ครูบิ๊ก",
      "เวลาว่างครูบิ๊ก",
    ],
  });



  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };

  const example = [
    {
      user: "admin",
      permission: [
        "ครูเติม",
        "เวลาว่างครูเติม",
        "ครูมีน",
        "เวลาว่างครูมีน",
        "ครูกิต",
        "เวลาว่างครูกิต",
        "ครูวี",
        "เวลาว่างครูวี",
        "ครูพี",
        "เวลาว่างครูพี",
        "ครูบิ๊ก",
        "เวลาว่างครูบิ๊ก",
      ],
    },
    {
      user: "term",
      permission: ["ครูเติม", "เวลาว่างครูเติม"],
    },
    {
      user: "meen",
      permission: ["ครูมีน", "เวลาว่างครูมีน"],
    },
    {
      user: "kit",
      permission: ["ครูกิต", "เวลาว่างครูกิต"],
    },
    {
      user: "wee",
      permission: ["ครูวี", "เวลาว่างครูวี"],
    },
    {
      user: "pea",
      permission: ["ครูพี", "เวลาว่างครูพี"],
    },
    {
      user: "big",
      permission: ["ครูบิ๊ก", "เวลาว่างครูบิ๊ก"],
    },
  ];


  
  // for(const i in data){
  //   console.log(Object.entries(data[i]))

  // }
  let Arr = [];
  for (const i in props.data) {
    // Object.keys(data[i])
    //   .filter(function (k) {
    //     return !/_/.test(k);
    //   })
    //   .forEach(function (k) {
    //     console.log(data[i][k]);
    //   });
    var keysToIterateThrough = ["title"];
    keysToIterateThrough.forEach((key) => {
      var value = props.data[i][key];
      // console.log(`key: ${key} => value: ${value}`);
      Arr.push(value);
    });
  }
  props.handleCallback(userinfo.response);

  // console.log(Arr);
  return (
    <React.Fragment>
      <p className="text-gray-500 font-bold mt-10">Tutors</p>
      {example.map((item, idx) => (
        <>
          {item.user.includes(location.state.id) ? (
            <>
              {item.permission.map((label, i) => (
                <>
                  {Arr.includes(label) ? (
                    <div key={i}>
                      {console.log(label)}
                      <label className="items-center mt-3 block">
                        <input
                          type="checkbox"
                          defaultChecked
                          onChange={handleChange}
                          className={`form-checkbox h-5 w-5 text-${label}-400 rounded focus:ring-0 cursor-pointer`}
                          name="user"
                          value={label}
                        />
                        <span className="ml-2 text-base text-gray-700 capitalize">
                          {label}
                        </span>
                      </label>
                    </div>
                  ) : null}
                </>
              ))}
            </>
          ) : null}
        </>
      ))}
    </React.Fragment>
  );
}
