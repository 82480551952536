import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";

export default function EditBlogModal(props) {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const { isOpen, username, handleClose, selectedBlog, date } = props;

  useEffect(() => {
    if (Object.keys(selectedBlog).length !== 0) {
      setTitle(selectedBlog.title);
      setDesc(selectedBlog.description);
    }
  }, [selectedBlog]);

  const editBlog = (e) => {
    e.preventDefault();

    const eventObject = {
      title: title,

      description: desc,
    };

    axios
      .put(
        "https://data.galileo-tutor.com/blog/edit-blog/" + selectedBlog._id,
        eventObject
      )
      .then((res) => {
        console.log(res.data);
        console.log("Event successfully updated");
      })
      .catch((error) => {
        console.log(error);
      });
    handleClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 mt-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative max-w-screen-lg transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                {/* Main Content */}

                <div className="bg-white rounded-2xl">
                  <header className="bg-gray-100 px-4 py-2 flex justify-between rounded-t-2xl items-center">
                    <span className="material-icons-outlined text-gray-400">
                      drag_handle
                    </span>
                    <p className="font-bold text-xl text-gray-800">
                      เพิ่มบทความ
                    </p>
                    <div>
                      {/* <span
                          className=" text-white bg-red-500 rounded-md px-1  cursor-pointer"
                          onClick={deleteEvent}
                        >
                          ลบ
                        </span> */}
                      <button>
                        <span
                          className="material-icons-outlined text-gray-400"
                          onClick={handleClose}
                        >
                          close
                        </span>
                      </button>
                    </div>
                  </header>
                  <div className="p-7">
                    <label className="text-xl font-bold">
                      ชื่อบทความ
                    </label>
                    <br />
                    <input
                      type="text"
                      name="title"
                      placeholder=""
                      value={title}
                      required
                      className="pt-3 border-0 text-gray-900 text-xl  pb-2 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <br />
                    <br />
                    <label className="text-xl  font-bold">
                      รายละเอียด
                    </label>
                    <br />
                    <textarea
                      type="text"
                      name="title"
                      placeholder=""
                      value={desc}
                      rows={5}
                      required
                      className="pt-3 border-0 text-gray-900 text-xl w-full pb-2 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                      onChange={(e) => setDesc(e.target.value)}
                    />
                    <br />
                    <br />
                    <label className="text-xl  font-bold">
                      วันที่ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="font-normal">
                        26 มิถุนายน 2023
                      </span>
                    </label>
                  </div>
                  <footer className="flex justify-end border-t p-3 mt-5">
                    {title === "" || desc === "" ? (
                      <button
                        type="submit"
                        onClick={editBlog}
                        className="bg-gray-400 ease-in-out duration-150 cursor-not-allowed px-6 py-2 rounded-lg text-white "
                        disabled
                      >
                        แก้ไข
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={editBlog}
                        className="bg-gray-800 ease-in-out duration-150 hover:bg-gray-900 px-6 py-2 rounded-lg text-white "
                      >
                        แก้ไข
                      </button>
                    )}
                  </footer>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
