import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";

export default function EditSaveForm(props) {
  const {
    isOpen,
    selectedDay,
    username,
    selectedEvent,
    handleClose,
    savedStream,
    selectedLabel,
  } = props;



  const [eventTitle, setEventTitle] = useState("");
  const [eventStart, setEventStart] = useState("");
  const [eventEnd, setEventEnd] = useState("");
  const [eventSelectedLabel, setEventSelectedLabel] = useState("");



  useEffect(() => {
    if (Object.keys(selectedEvent).length !== 0) {
      setEventTitle(selectedEvent.name);
      setEventStart(selectedEvent.start);
      setEventEnd(selectedEvent.end);
      setEventSelectedLabel(selectedEvent.title);
    }
  }, [selectedEvent]);

  const example = [
    {
      user: "admin",
      permission: [
        "ครูเติม",
        "เวลาว่างครูเติม",
        "ครูมีน",
        "เวลาว่างครูมีน",
        "ครูกิต",
        "เวลาว่างครูกิต",
        "ครูวี",
        "เวลาว่างครูวี",
        "ครูพี",
        "เวลาว่างครูพี",
        "ครูบิ๊ก",
        "เวลาว่างครูบิ๊ก",
      ],
    },
    {
      user: "term",
      permission: ["ครูเติม", "เวลาว่างครูเติม"],
    },
    {
      user: "meen",
      permission: ["ครูมีน", "เวลาว่างครูมีน"],
    },
    {
      user: "kit",
      permission: ["ครูกิต", "เวลาว่างครูกิต"],
    },
    {
      user: "wee",
      permission: ["ครูวี", "เวลาว่างครูวี"],
    },
    {
      user: "pea",
      permission: ["ครูพี", "เวลาว่างครูพี"],
    },
    {
      user: "big",
      permission: ["ครูบิ๊ก", "เวลาว่างครูบิ๊ก"],
    },
  ];

  const dict = {
    ครูเติม: "term",
    เวลาว่างครูเติม: "term",
    ครูมีน: "meen",
    เวลาว่างครูมีน: "meen",
    ครูกิต: "kit",
    เวลาว่างครูกิต: "kit",
    ครูวี: "wee",
    เวลาว่างครูวี: "wee",
    ครูพี: "pea",
    เวลาว่างครูพี: "pea",
    ครูบิ๊ก: "big",
    เวลาว่างครูบิ๊ก: "big",
  };




  const editEvent = (e) => {
    e.preventDefault();

    const eventObject = {
      owner: `admin ${dict[eventSelectedLabel]}`,
    //   date: selectedDay,
      title: eventSelectedLabel,
      name: eventTitle,
      start: eventStart,
      end: eventEnd,
    };

    axios
      .put(
        "https://data.galileo-tutor.com/saved/edit-save/" +
          selectedEvent._id,
        eventObject
      )
      .then((res) => {
        console.log(res.data);
        console.log("Event successfully updated");
      })
      .catch((error) => {
        console.log(error);
      });
    handleClose();
  };



  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 mt-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative max-w-screen-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                {/* Main Content */}

             
                  <div className="bg-white  rounded-2xl  shadow-xl ">
                    <header className="bg-gray-100 px-4 py-2 flex justify-between  rounded-t-2xl items-center">
                      <span className="material-icons-outlined text-gray-400">
                        drag_handle
                      </span>
                      <div>
                    
                        <button>
                          <span
                            className="material-icons-outlined  text-gray-400"
                            onClick={handleClose}
                          >
                            close
                          </span>
                        </button>
                      </div>
                    </header>
                    <div className="p-3">
                      <div className="grid grid-cols-1/5 items-center gap-y-7">
                        <span className="material-icons-outlined text-gray-400 text-center">
                          person_outline
                        </span>
                        <p className="ml-3">
                          <input
                            type="text"
                            name="title"
                            placeholder="ชื่อนักเรียน"
                            value={eventTitle}
                            required
                            className="pt-3 border-0 text-gray-900 text-xl font-semibold pb-2 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                            onChange={(e) =>
                              setEventTitle(e.target.value)
                            }
                          />
                          <br />
                        </p>

                        <span className="material-icons-outlined text-gray-400 text-center">
                          schedule
                        </span>
                        <p className="ml-3">
                          เริ่มตั้งแต่&nbsp;
                          <input
                            type="time"
                            name="start"
                            value={eventStart}
                            required
                            className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 ml-4 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                            onChange={(e) =>
                              setEventStart(e.target.value)
                            }
                          />
                          <br />
                          จนถึง&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="time"
                            name="end"
                            value={eventEnd}
                            required
                            className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 ml-4 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                            onChange={(e) =>
                              setEventEnd(e.target.value)
                            }
                          />
                          <br />
                        </p>

                        <span className="material-icons-outlined text-gray-400 text-center">
                          bookmark_border
                        </span>
                        <div
                          className={
                            username === "admin"
                              ? `grid grid-cols-4 gap-x-3 gap-y-3`
                              : `grid grid-cols-3 gap-x-3 gap-y-3`
                          }
                        >
                          {example.map((item, idx) => (
                            <>
                              {item.user === username ? (
                                <>
                                  {item.permission.map((label, i) => (
                                    <div
                                      className="text-center cursor-pointer bg-gray-100 py-4 rounded-xl"
                                      onClick={
                                        label.includes("เวลาว่าง")
                                          ? () => {
                                              setEventSelectedLabel(
                                                label
                                              );
                                              setEventTitle(label);
                                            }
                                          : () => {
                                              setEventSelectedLabel(
                                                label
                                              );
                                              setEventTitle(
                                                eventTitle
                                              );
                                            }
                                      }
                                    >
                                      <div className="flex items-center justify-center">
                                        <span
                                          key={i}
                                          className={`bg-${label}-500 w-6 h-6 rounded-full  flex items-center justify-center cursor-pointer`}
                                        >
                                          {eventSelectedLabel ===
                                            label && (
                                            <span className="material-icons-outlined text-white text-sm">
                                              check
                                            </span>
                                          )}
                                        </span>
                                      </div>

                                      <p className="text-sm mt-1">
                                        {label}
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ) : null}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                    <footer className="flex justify-end border-t p-3 mt-5">
                      {eventTitle === "" ||
                      eventStart === "" ||
                      eventEnd === "" ||
                      eventSelectedLabel === "" ? (
                        <button
                          type="submit"
                          onClick={editEvent}
                          className="bg-gray-400 ease-in-out duration-150 cursor-not-allowed px-6 py-2 rounded-lg text-white "
                          disabled
                        >
                          แก้ไข
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={editEvent}
                          className="bg-gray-800 ease-in-out duration-150 hover:bg-gray-900 px-6 py-2 rounded-lg text-white "
                        >
                          แก้ไข
                        </button>
                      )}
                    </footer>
                  </div>
        
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

      
      </Dialog>
    </Transition>
  );
}
