import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { BsArrowRight, BsPlusLg } from "react-icons/bs";

import axios from "axios";
import CreateSavedModal from "./CreateSaveModal";
import CreateSaveForm from "./CreateSaveForm";
import EditSaveForm from "./EditSaveForm";

export default function SavedModal(props) {
  const {
    isOpen,
    username,
    savedStream,
    selectedDay,
    type,
    handleClose,
  } = props;

  const [saved, setSaved] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);

  const [formOpen, setFormOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [date, setDate] = useState("");

  const dict = {
    ครูเติม: "white",
    เวลาว่างครูเติม: "ครูเติม-200",
    ครูมีน: "white",
    เวลาว่างครูมีน: "ครูมีน-200",
    ครูกิต: "white",
    เวลาว่างครูกิต: "ครูกิต-200",
    ครูวี: "white",
    เวลาว่างครูวี: "ครูวี-200",
    ครูพี: "white",
    เวลาว่างครูพี: "ครูพี-200",
    ครูบิ๊ก: "white",
    เวลาว่างครูบิ๊ก: "ครูบิ๊ก-200",
  };

  const dict1 = {
    ครูเติม: "term",
    เวลาว่างครูเติม: "term",
    ครูมีน: "meen",
    เวลาว่างครูมีน: "meen",
    ครูกิต: "kit",
    เวลาว่างครูกิต: "kit",
    ครูวี: "wee",
    เวลาว่างครูวี: "wee",
    ครูพี: "pea",
    เวลาว่างครูพี: "pea",
    ครูบิ๊ก: "big",
    เวลาว่างครูบิ๊ก: "big",
  };

  useEffect(() => {
    axios.get("https://data.galileo-tutor.com/saved").then((res) => {
      setSaved(res.data);
    });
  }, [savedStream]);

  const monday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Monday");

  const tuesday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Tuesday");

  const wednesday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Wednesday");

  const thursday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Thursday");
  const friday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Friday");

  const saturday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Saturday");

  const sunday = saved
    .sort(
      (a, b) => a.start.replace(":", "") - b.start.replace(":", "")
    )
    .filter((x) => x.owner.includes(username))
    .filter((x) => formatDate(x.date) === "Sunday");

  function deleteSaved(id) {
    axios
      .delete(
        "https://data.galileo-tutor.com/saved/delete-save/" + id
      )
      .then((res) => {
        console.log("Save successfully deleted!");
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(id);
  }

  function formatDate(dateString) {
    const [day, month, year] = dateString.split("/");
    const date = new Date(`${month}/${day}/${year}`);
    const options = { weekday: "long" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          onClose={handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 mt-20 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-lg transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                  {/* Main Content */}

                  <div className="bg-white rounded-2xl">
                    <header className="bg-gray-100 px-4 py-2 flex justify-between rounded-t-2xl items-center">
                      <span className="material-icons-outlined text-gray-400">
                        drag_handle
                      </span>

                      <p className="text-xl font-bold text-gray-800">
                        ที่บันทึกไว้
                      </p>

                      <button>
                        <span
                          className="material-icons-outlined text-gray-400"
                          onClick={handleClose}
                        >
                          close
                        </span>
                      </button>
                    </header>
                    <div className="p-8">
                      <div className="">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันจันทร์
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < monday.length;
                                  i++
                                ) {
                                  monday[i].date = selectedDay;
                                  delete monday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    monday,

                                    {
                                      headers: {
                                        "Content-Type":
                                          "application/json",
                                      },
                                    }
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>

                        <div className=" grid grid-cols-5 gap-4">
                          {monday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("12/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>

                        {/* <div className=" grid grid-cols-5 gap-4">
                          {saved.map((item, idx) => (
                            <>
                              {item.owner.includes(username) ? (
                                <>
                                  {formatDate(item.date) ===
                                  "Monday" ? (
                                    <div
                                      key={idx}
                                      className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                                    >
                                      <h1 className=" font-bold text-2xl text-gray-700">
                                        {item.name}
                                      </h1>
                                      <p className=" text-lg text-gray-700">
                                        {item.start} - {item.end}
                                      </p>
                                      <div className="mt-3">
                                        {console.log(item)}
                                        {type === "paste" ? (
                                          <button
                                            className={`bg-${
                                              dict[item.title]
                                            } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                            onClick={() => {
                                              axios
                                                .post(
                                                  `https://data.galileo-tutor.com/schedule/create-event`,
                                                  {
                                                    owner: `admin ${
                                                      dict1[
                                                        item.title
                                                      ]
                                                    }`,
                                                    date: selectedDay,
                                                    title: item.title,
                                                    name: item.name,
                                                    start: item.start,
                                                    end: item.end,
                                                  }
                                                )
                                                .then((res) =>
                                                  console.log(
                                                    res.data
                                                  )
                                                );

                                              handleClose();
                                            }}
                                          >
                                            วาง{" "}
                                            <BsArrowRight className="inline" />
                                          </button>
                                        ) : null}
                                        <button
                                          className={`bg-${
                                            dict[item.title]
                                          } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                          onClick={() => {
                                            setFormOpen(true);
                                            setSelectedEvent(item);
                                          }}
                                        >
                                          แก้ไข
                                        </button>
                                        <button
                                          className={`bg-${
                                            dict[item.title]
                                          } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                          onClick={() =>
                                            deleteSaved(item._id)
                                          }
                                        >
                                          ลบ
                                        </button>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("12/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div> */}
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันอังคาร
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < tuesday.length;
                                  i++
                                ) {
                                  tuesday[i].date = selectedDay;
                                  delete tuesday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    tuesday
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>

                        <div className=" grid grid-cols-5 gap-4">
                          {tuesday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("13/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันพุธ
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < wednesday.length;
                                  i++
                                ) {
                                  wednesday[i].date = selectedDay;
                                  delete wednesday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    wednesday
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>

                        <div className=" grid grid-cols-5 gap-4">
                          {wednesday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("14/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันพฤหัสบดี
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < thursday.length;
                                  i++
                                ) {
                                  thursday[i].date = selectedDay;
                                  delete thursday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    thursday
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>

                        <div className=" grid grid-cols-5 gap-4">
                          {thursday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("15/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันศุกร์
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < friday.length;
                                  i++
                                ) {
                                  friday[i].date = selectedDay;
                                  delete friday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    friday
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>
                        <div className=" grid grid-cols-5 gap-4">
                          {friday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("16/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันเสาร์
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < saturday.length;
                                  i++
                                ) {
                                  saturday[i].date = selectedDay;
                                  delete saturday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    saturday
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>
                        <div className=" grid grid-cols-5 gap-4">
                          {saturday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("17/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="mt-10">
                        <div className="flex justify-between">
                          <p className="text-2xl font-bold mb-5">
                            วันอาทิตย์
                          </p>
                          {type === "paste" ? (
                            <button
                              className="border p-2 rounded-full flex items-center ease-in-out duration-150 hover:bg-gray-200 hover:border-transparent "
                              onClick={() => {
                                for (
                                  let i = 0;
                                  i < sunday.length;
                                  i++
                                ) {
                                  sunday[i].date = selectedDay;
                                  delete sunday[i]._id;
                                }

                                axios
                                  .post(
                                    `https://data.galileo-tutor.com/schedule/create-multi`,
                                    sunday
                                  )
                                  .then((res) =>
                                    console.log(res.data)
                                  );
                                handleClose();
                              }}
                            >
                              <span className="pl-3 pr-3">
                                วางทั้งวัน{" "}
                                <BsArrowRight className="inline" />
                              </span>
                            </button>
                          ) : null}
                        </div>

                        <div className=" grid grid-cols-5 gap-4">
                          {sunday.map((item, idx) => (
                            <div
                              key={idx}
                              className={`relative bg-${item.title}-200 p-5 rounded-2xl  `}
                            >
                              <h1 className=" font-bold text-2xl text-gray-700">
                                {item.name}
                              </h1>
                              <p className=" text-lg text-gray-700">
                                {item.start} - {item.end}
                              </p>
                              <div className="mt-3">
                                {type === "paste" ? (
                                  <button
                                    className={`bg-${
                                      dict[item.title]
                                    } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                    onClick={() => {
                                      axios
                                        .post(
                                          `https://data.galileo-tutor.com/schedule/create-event`,
                                          {
                                            owner: `admin ${
                                              dict1[item.title]
                                            }`,
                                            date: selectedDay,
                                            title: item.title,
                                            name: item.name,
                                            start: item.start,
                                            end: item.end,
                                          }
                                        )
                                        .then((res) =>
                                          console.log(res.data)
                                        );

                                      handleClose();
                                    }}
                                  >
                                    วาง{" "}
                                    <BsArrowRight className="inline" />
                                  </button>
                                ) : null}
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } mr-1 py-2 px-5 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() => {
                                    setFormOpen(true);
                                    setSelectedEvent(item);
                                  }}
                                >
                                  แก้ไข
                                </button>
                                <button
                                  className={`bg-${
                                    dict[item.title]
                                  } py-2 px-3 rounded-lg mt-1 ease-in-out duration-100 sc`}
                                  onClick={() =>
                                    deleteSaved(item._id)
                                  }
                                >
                                  ลบ
                                </button>
                              </div>
                            </div>
                          ))}
                          <div
                            className="flex justify-center items-center border-2 border-gray-200 border-dashed p-5 rounded-2xl ease-in-out duration-100 cursor-pointer  hover:bg-gray-100"
                            onClick={() => {
                              setOpenDialog(true);
                              setDate("18/06/2023");
                            }}
                          >
                            <h1 className="text-center font-bold text-2xl text-gray-400">
                              <BsPlusLg className="text-center text-3xl font-bold inline mb-2" />
                              <br />
                              เพิ่มบันทึก
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <CreateSavedModal
        isOpen={openDialog}
        username={username}
        savedStream={savedStream}
        date={date}
        handleClose={() => {
          setOpenDialog(false);
        }}
      />
      <EditSaveForm
        isOpen={formOpen}
        username={username}
        savedStream={savedStream}
        selectedEvent={selectedEvent}
        handleClose={() => {
          setFormOpen(false);
          setSelectedEvent({});
        }}
      />
    </>
  );
}
