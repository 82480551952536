import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Modal from "./Modal";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import ChooseSavedModal from "./ChooseSavedModal";
import SavedModal from "./SavedModal";
import Swal from "sweetalert2";
import axios from "axios";

export default function LabelModal(props) {
  const {
    isOpen,
    selectedDay,
    username,
    selectedEvent,
    handleClose,
    savedStream,
    changeStream,
  } = props;

  const [closeLabelModal, setCloseLabelModal] = useState(false);

  const Callback = (childData) => {
    setCloseLabelModal(childData);
  };
  useEffect(() => {
    if (closeLabelModal === true) {
      handleClose();
    }
  }, [closeLabelModal]);

  if (Object.keys(selectedEvent).length !== 0) {
    handleClose();

    // console.log(getIsOpen)
  }

  const [selectedLabel, setSelectedLabel] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openSave, setOpenSave] = useState(false);

  const dict = {
    ครูเติม: "เพิ่มตารางสอนครู",
    เวลาว่างครูเติม: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูมีน: "เพิ่มตารางสอนครู",
    เวลาว่างครูมีน: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูกิต: "เพิ่มตารางสอนครู",
    เวลาว่างครูกิต: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูวี: "เพิ่มตารางสอนครู",
    เวลาว่างครูวี: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูพี: "เพิ่มตารางสอนครู",
    เวลาว่างครูพี: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
    ครูบิ๊ก: "เพิ่มตารางสอนครู",
    เวลาว่างครูบิ๊ก: "เพิ่มตารางว่างส่งให้ผู้ปกครอง",
  };

  const example = [
    {
      user: "admin",
      permission: [
        "ครูเติม",
        "เวลาว่างครูเติม",
        "ครูมีน",
        "เวลาว่างครูมีน",
        "ครูกิต",
        "เวลาว่างครูกิต",
        "ครูวี",
        "เวลาว่างครูวี",
        "ครูพี",
        "เวลาว่างครูพี",
        "ครูบิ๊ก",
        "เวลาว่างครูบิ๊ก",
      ],
    },
    {
      user: "term",
      permission: ["ครูเติม", "เวลาว่างครูเติม"],
    },
    {
      user: "meen",
      permission: ["ครูมีน", "เวลาว่างครูมีน"],
    },
    {
      user: "kit",
      permission: ["ครูกิต", "เวลาว่างครูกิต"],
    },
    {
      user: "wee",
      permission: ["ครูวี", "เวลาว่างครูวี"],
    },
    {
      user: "pea",
      permission: ["ครูพี", "เวลาว่างครูพี"],
    },
    {
      user: "big",
      permission: ["ครูบิ๊ก", "เวลาว่างครูบิ๊ก"],
    },
  ];

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 mt-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative max-w-screen-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                {/* Main Content */}

                <div className="bg-white rounded-2xl">
                  <header className="bg-gray-100 px-4 py-2 flex justify-between rounded-t-2xl items-center">
                    <span className="material-icons-outlined text-gray-400">
                      drag_handle
                    </span>

                    <div>
                      {/* <span
                           className=" text-white bg-red-500 rounded-md px-1  cursor-pointer"
                           onClick={deleteEvent}
                         >
                           ลบ
                         </span> */}
                      <button>
                        <span
                          className="material-icons-outlined text-gray-400"
                          onClick={handleClose}
                        >
                          close
                        </span>
                      </button>
                    </div>
                  </header>
                  <div className="p-3">
                    <div className="items-center">
                      <div
                        className={
                          username === "admin"
                            ? `grid grid-cols-4 gap-x-3 gap-y-3`
                            : `grid grid-cols-2 gap-x-3 gap-y-3`
                        }
                      >
                        {example.map((item, idx) => (
                          <>
                            {item.user === username ? (
                              <>
                                {item.permission.map((label, i) => (
                                  <div
                                    className="text-center cursor-pointer bg-gray-100 py-4 px-4 rounded-xl ease-in-out duration-100 hover:bg-gray-200"
                                    onClick={() => {
                                      setSelectedLabel(label);

                                      setOpenDialog(true);
                                    }}
                                  >
                                    <div className="flex items-center justify-center">
                                      <span
                                        key={i}
                                        className={`bg-${label}-500 w-10 h-10 rounded-full  flex items-center justify-center cursor-pointer`}
                                      >
                                        {/* {selectedLabel === label && (
                                           <span className="material-icons-outlined text-white text-sm">
                                             check
                                           </span>
                                         )} */}
                                      </span>
                                    </div>

                                    <p className="text-lg mt-1">
                                      {dict[label]}
                                    </p>
                                  </div>
                                ))}
                              </>
                            ) : null}
                          </>
                        ))}
                        <div
                          className="text-center cursor-pointer bg-gray-100 py-4 px-4 rounded-xl ease-in-out duration-100 hover:bg-gray-200"
                          onClick={() => {
                            // setSelectedLabel(label);
                            setOpenSave(true);
                            setCloseLabelModal(false);
                          }}
                        >
                          <p className="text-lg mt-1">
                            <AiOutlinePlus className="inline font-bold" />{" "}
                            &nbsp;วางจากที่บันทึกไว้
                          </p>
                        </div>
                        <div
                          className="text-center cursor-pointer bg-gray-100 py-4 px-4 rounded-xl ease-in-out duration-100 hover:bg-gray-200"
                          onClick={() => {
                            Swal.fire({
                              title: "ต้องการลบทั้งวันหรือไม่ ?",
                              showDenyButton: true,
                              showCancelButton: false,
                              confirmButtonText: "ยืนยัน",
                              denyButtonText: `ยกเลิก`,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                axios
                                  .delete(
                                    "https://data.galileo-tutor.com/schedule/delete-day/",
                                    { data: { selectedDay } }
                                  )
                                  .then((res) => {
                                    console.log(
                                      "Date schedule successfully deleted!"
                                    );
                                    handleClose()
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }
                            });
                          }}
                        >
                          <p className="text-lg mt-1">
                            <AiOutlineDelete className="inline font-bold" />{" "}
                            &nbsp;ลบทั้งวัน
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <Modal
          isOpen={openDialog}
          selectedDay={selectedDay}
          username={username}
          selectedEvent={selectedEvent}
          changeStream={changeStream}
          savedStream={savedStream}
          selectedLabel={selectedLabel}
          handleCallback={Callback}
          handleClose={() => {
            setOpenDialog(false);
            setSelectedLabel("");
          }}
        />

        <SavedModal
          isOpen={openSave}
          savedStream={savedStream}
          username={username}
          type="paste"
          selectedDay={selectedDay}
          handleClose={() => {
            setOpenSave(false);
          }}
        />

        {/* <ChooseSavedModal
          isOpen={openSave}
          selectedDay={selectedDay}
          savedStream={savedStream}
          username={username}
          selectedLabel={selectedLabel}
          handleCallback={Callback}
          handleClose={() => {
            setOpenSave(false);
          }}
        /> */}
      </Dialog>
    </Transition>
  );
}
