import React, { useEffect, useState } from "react";
import Day from "./Day";

export default function Month(props) {
  const { month, checkStatus, data, changeStream, savedStream } = props;

  


  return (
    <>
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, idx) => (
            <Day
              day={day}
              key={idx}
              rowIdx={i}
              checkStatus={checkStatus}
              data={data}
              changeStream={changeStream}
              savedStream={savedStream}
            />
          ))}
        </React.Fragment>
      ))}
    </>
  );
}
