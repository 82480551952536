import React, { useState } from "react";
import CreateEventButton from "./CreateEventButton";
import SmallCalendar from "./SmallCalendar";
import Labels from "./Labels";
import AdminLabels from "./AdminLabels";
import { useLocation } from "react-router-dom";

export default function Sidebar(props) {
  const [checkStatus, setCheckStatus] = useState([]);

  const location = useLocation();

  function Callback(childData) {
    setCheckStatus(childData);
  }



  props.handleCallback(checkStatus)

  return (
    <aside className="border p-5 w-64 block ">
      <CreateEventButton savedStream={props.savedStream} />
      <SmallCalendar />
      {location.state.id === "admin" ? (
        <AdminLabels handleCallback={Callback} data={props.data}  />
      ) : (
        <Labels handleCallback={Callback} data={props.data}  />
      )}

    </aside>
  );
}
