import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";

export default function Class() {
  const history = useNavigate();
  const location = useLocation();

  const [RoomCode, setRoomCode] = useState("");
  const [Class, setClass] = useState([]);

  var dict = {
    ครูเติม: "kruterm",
    ครูบิ๊ก: "krubig",
    ครูพี: "krupea",
    ครูวี: "kruwee",
    ครูมีน: "krumeen",
    ครูกิต: "krukit",
  };

  useEffect(() => {
    axios
      .get(`https://data.galileo-tutor.com/user`)
      .then((res) => {
        setClass(res.data);
        console.log(Class);
      })
      .catch((error) => {
        console.log(error);
      });

  
  }, [Class]);
  
  let i = 0;
  for (i in Class) {
    if (
      Class[i].username === location.state.id &&
      RoomCode === ""
    ) {
      setRoomCode(dict[Class[i].class[0]]);
    }
  }

  console.log("asd" + RoomCode);

  const submitCode = (e) => {
    e.preventDefault();
    history("/room", {
      state: { id: location.state.id, roomID: RoomCode },
    });
  };

  return (
    <div>
      <div className="min-h-screen flex items-center justify-center">
        <form
          onSubmit={submitCode}
          className="flex flex-col items-center justify-center  shadow-xl rounded-3xl  p-24"
        >
          <label className="text-5xl mb-10 font-bold text-gray-800">
            เลือกครูที่ต้องการเรียน
          </label>
          {/* <input
          type="text"
          required
          placeholder="Enter Room Code"
          value={RoomCode}
          onChange={(e) => setRoomCode(e.target.value)}
          className="border border-gray-800"
        /> */}
          <div className="flex  gap-x-5">
            <select
              name="room"
              className="text-2xl py-5 pr-10 pl-5 rounded-2xl bg-gray-100 border-0 focus:ring-0"
              onChange={(e) => setRoomCode(e.target.value)}
              value={RoomCode}
            >
              {Class.map((item, idx) => (
                <>
                  {item.username === location.state.id ? (
                    <>
                      {item.class.map((room, index) => (
                        <>
                          <option value={dict[room]}>{room}</option>
                        </>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </select>
            <button
              className="bg-purple-500 py-5 px-10 rounded-2xl text-white font-bold text-2xl w-full"
              type="submit"
            >
              เข้าห้องเรียน
              <BsArrowRight className="inline ml-3" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
