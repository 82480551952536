import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Login() {
  const history = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  async function submit(e) {
    e.preventDefault();

    try {
      await axios
        .post("https://data.galileo-tutor.com/user/login", {
          username,
          password,
        })
        .then((res) => {
          if (res.data === "exist") {
            history("/schedule", { state: { id: username } });
            window.username = username;
            // history("/class", { state: { id: username } });
          } else if (res.data === "admin") {
            Swal.fire({
              title: "Where do you want to go?",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: "ตารางสอน",
              denyButtonText: `จัดการ Blogs`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // window.location.href =
                //   "https://meet.galileo-tutor.com/preview/sas-jxnl-avz";
                // history("/class", { state: { id: username } });
                history("/schedule", { state: { id: username } });
              } else if (result.isDenied) {
                history("/blog", { state: { id: username } });
              }
            });
          } else if (res.data === "notexist") {
            Swal.fire({
              title: "Login Failed",
              text: "Incorrect username or password.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          alert("Wrong details");
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="bg-job relative ">
      {/* <img src="./logo.png" className="absolute w-60 m-10" /> */}
      <div className="flex  items-center  justify-center min-h-screen">
        <form className="flex flex-col bg-white p-10 mx-10 sm:mx-0 sm:p-20 rounded-3xl shadow-xl my-32">
          <img src="./term.png" className="w-24 mx-auto" alt="" />

          <h1 className="font-bold my-3 text-center text-4xl sm:text-5xl text-gray-800">
            Welcome<span className="hidden sm:inline"> back</span>
          </h1>

          <p className="mb-5  text-xl text-center text-gray-500">
            Enter your details to get started
          </p>
          {/* <label className="text-xl">Username</label> */}
          <input
            className="border-2 mb-5 mt-1 rounded-xl px-5 w-full border-gray-100 text-2xl ease-in-out duration-150 focus:ring-purple-500"
            type="text"
            name="username"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          ></input>
          {/* <label className="text-xl">Password</label> */}
          <input
            className="border-2 mb-5 mt-1 rounded-xl px-5 w-full border-gray-100 text-2xl ease-in-out duration-150 focus:ring-purple-500"
            type="password"
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <input
            className="cursor-pointer my-3 rounded-xl px-5 py-3 font-bold w-full bg-purple-500 text-white text-2xl ease-in-out duration-150 hover:bg-purple-700 "
            type="submit"
            value="Log In"
            onClick={submit}
          ></input>
        </form>
      </div>
    </div>
  );
}
