import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import React from "react";
import { useLocation } from "react-router-dom";

export default function Room() {
  const location = useLocation();
  console.log(location.state.roomID);

  const meeting = async (element) => {
    const appID = 487452371;
    const serverSecret = "89469e4ecbc505f1c607529e7df3d53d";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appID,
      serverSecret,
      location.state.roomID,
      Date.now().toString(),
      location.state.id
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
    });
  };
  return (
    <div>
      <div
        ref={meeting}
        style={{ width: "100vw", height: "100vh" }}
      ></div>
    </div>
  );
}
