import dayjs from "dayjs";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Modal from "./Modal";
import LabelModal from "./LabelModal";

export default function Day(props) {
  const {
    day,
    rowIdx,
    checkStatus,
    data,
    changeStream,
    savedStream,
  } = props;
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const [selectedDay, setSelectedDay] = useState("");
  const [username, setUsername] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-gray-600 text-white rounded-full w-7"
      : "";
  }

  return (
    <div className="border border-gray-200 day hover:bg-gray-50 ease-in-out duration-150 flex flex-col">
      {/* {checkStatus} */}
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <p className="text-sm mt-1">
            {day.format("ddd").toUpperCase()}
          </p>
        )}
        <p
          className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <div
        className="flex-1 cursor-pointer pb-10"
        onClick={() => {
          setSelectedDay(day.format("DD/MM/YYYY"));
          setOpenDialog(true);
          setUsername(location.state.id);
          // setSelectedEvent({});
        }}
      >
        {data
          .sort(
            (a, b) =>
              a.start.replace(":", "") - b.start.replace(":", "")
          )
          .map((item, idx) => (
            <>
              {item.owner.includes(location.state.id) ? (
                <>
                  {item.date === day.format("DD/MM/YYYY") ? (
                    <>
                      {checkStatus.includes(item.title) ? (
                        <div
                          key={idx}
                          onClick={() => {
                            setSelectedDay(day.format("DD/MM/YYYY"));
                            setIsOpen(true);

                            setUsername(location.state.id);
                            setSelectedEvent(item);
                          }}
                          className={`bg-${item.title}-200 px-1 pt-1 pb-2 ml-1 mr-3 text-gray-600 text-sm rounded-md mb-1  ease-in-out duration-150 sc`}
                        >
                          <p>
                            <span className="font-bold">
                              {item.name}
                            </span>{" "}
                            &nbsp;&nbsp;
                            <br className="block 2xl:hidden" />
                            {item.start} - {item.end}
                          </p>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ))}
      </div>
      <LabelModal
        isOpen={openDialog}
        selectedDay={selectedDay}
        username={username}
        selectedEvent={selectedEvent}
        changeStream={changeStream}
        savedStream={savedStream}
        handleClose={() => {
          setOpenDialog(false);
          // setSelectedEvent({});
        }}
      />
      <Modal
        isOpen={isOpen}
        selectedDay={selectedDay}
        username={username}
        selectedEvent={selectedEvent}
        changeStream={changeStream}
        savedStream={savedStream}
        handleClose={() => {
          setIsOpen(false);
          setSelectedEvent({});
        }}
      />
    </div>
  );
}
