import React, {
  useState,
  useContext,
  useEffect,
  createRef,
} from "react";
import "./App.css";
import { getMonth } from "./util";
import CalendarHeader from "./components/CalendarHeader";
import Sidebar from "./components/Sidebar";
import Month from "./components/Month";
import GlobalContext from "./context/GlobalContext";
import EventModal from "./components/EventModal";
// import { createFileName, useScreenshot } from "use-react-screenshot";
import * as Realm from "realm-web";
import axios from "axios";
import { useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import "dayjs/locale/th";
const app = new Realm.App({ id: "application-0-eligt" });

function Schedule() {
  dayjs.locale("th");
  const location = useLocation();
  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);

  const [user, setUser] = useState();
  const [changeStream, setChangeStream] = useState([]);
  const [savedStream, setSavedStream] = useState([]);

  useEffect(() => {
    const login = async () => {
      // Authenticate anonymously
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user); // Connect to the database

      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("test").collection("schedule"); // Everytime a change happens in the stream, add it to the list of events

      for await (const change of collection.watch()) {
        setChangeStream((events) => [...events, change]);
      }
    };
    login();
  }, []);

  useEffect(() => {
    const login = async () => {
      // Authenticate anonymously
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user); // Connect to the database

      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("test").collection("saved"); // Everytime a change happens in the stream, add it to the list of events

      for await (const change of collection.watch()) {
        setSavedStream((events) => [...events, change]);
      }
    };
    login();
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://data.galileo-tutor.com/schedule")
      .then((res) => {
        setData(res.data);
        console.log("fetched");
        // console.log(data);
      });
  }, [changeStream]);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  const [checkStatus, setCheckStatus] = useState([]);

  function Callback(childData) {
    setCheckStatus(childData);
  }
  // function activeLabel(childData) {
  //   setActive(childData);
  // }
  const [showHeader, setShowHeader] = useState("hidden");
  const [row, setRow] = useState("5");

  // const ref = createRef(null);

  // const [image, takeScreenshot] = useScreenshot({
  //   type: "image/jpeg",
  //   quality: 1.0,
  // });

  // const download = (
  //   image,
  //   { name = "scheduler", extension = "jpg" } = {}
  // ) => {
  //   const a = document.createElement("a");
  //   a.href = image;
  //   a.download = createFileName(extension, name);
  //   a.click();
  // };

  const delay = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  // const downloadScreen = async () => {
  //   takeScreenshot(ref.current).then(download);
  // };

  const captureScreenshot = () => {
    const elementToCapture = document.getElementById("elementId"); // Replace 'elementId' with the ID of the tag you want to capture

    html2canvas(elementToCapture).then((canvas) => {
      const screenshotUrl = canvas.toDataURL(); // This will contain the screenshot image URL

      const downloadLink = document.createElement("a");
      downloadLink.href = screenshotUrl;
      downloadLink.download = "screenshot.png"; // Specify the desired file name and extension

      // Trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const example = [
    {
      user: "admin",
      permission: [
        "ครูเติม",
        "เวลาว่างครูเติม",
        "ครูมีน",
        "เวลาว่างครูมีน",
        "ครูกิต",
        "เวลาว่างครูกิต",
        "ครูวี",
        "เวลาว่างครูวี",
        "ครูพี",
        "เวลาว่างครูพี",
        "ครูบิ๊ก",
        "เวลาว่างครูบิ๊ก",
      ],
    },
    {
      user: "term",
      permission: ["ครูเติม", "เวลาว่างครูเติม"],
    },
    {
      user: "meen",
      permission: ["ครูมีน", "เวลาว่างครูมีน"],
    },
    {
      user: "kit",
      permission: ["ครูกิต", "เวลาว่างครูกิต"],
    },
    {
      user: "wee",
      permission: ["ครูวี", "เวลาว่างครูวี"],
    },
    {
      user: "pea",
      permission: ["ครูพี", "เวลาว่างครูพี"],
    },
    {
      user: "big",
      permission: ["ครูบิ๊ก", "เวลาว่างครูบิ๊ก"],
    },
  ];

  const allowed_label = example.filter((x) =>
    x.user.includes(location.state.id)
  );

  const filteredLabel = checkStatus.filter((element) =>
    allowed_label[0].permission.includes(element)
  );

  var now = new Date();
  var date =
    now.getDate() +
    "/" +
    (now.getMonth() + 1) +
    "/" +
    now.getFullYear();

  var time = now.getHours() + ":" + now.getMinutes();

  return (
    <React.Fragment>
      {showEventModal && <EventModal />}

      <div className="h-screen notresponsive flex flex-col ">
        <CalendarHeader />

        <div className="flex flex-1">
          <Sidebar
            handleCallback={Callback}
            // activeLabel={activeLabel}
            data={data}
            savedStream={savedStream}
          />

          <div
            // ref={ref}
            id="elementId"
            className={`flex-1 grid grid-cols-7 grid-rows-${row}`}
          >
            <div
              className={`px-14 ${showHeader} items-center justify-between col-span-7`}
            >
              <p className="text-3xl text-gray-700">
                ปฏิธิน{" "}
                <span className="font-bold">{filteredLabel}</span>
                <br />
                เดือน{" "}
                {dayjs(new Date(dayjs().year(), monthIndex))
                  .locale("th")
                  .format("MMMM YYYY")}
              </p>
              <p className="text-lg text-gray-600 text-right">
                อัพเดทล่าสุดเมื่อ <br />
                วันที่ {date}
                <br />
                เวลา {time} น.
              </p>
            </div>
            <Month
              month={currenMonth}
              checkStatus={checkStatus}
              changeStream={changeStream}
              savedStream={savedStream}
              data={data}
            />
          </div>
        </div>
      </div>

      <button
        onClick={async () => {
          setShowHeader("flex");
          setRow("6");

          await delay(1000);
          // downloadScreen();
          captureScreenshot();
          await delay(1000);

          setShowHeader("hidden");
          setRow("5");
        }}
        className="bg-gray-800 top-3 right-3 z-50 py-2 px-3 rounded fixed ease-in-out duration-150 hover:bg-gray-900 text-white sc"
      >
        บันทึกรูป
      </button>
    </React.Fragment>
  );
}

export default Schedule;
