import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";

export default function CreateSaveForm(props) {
  const {
    isOpen,
    username,
    handleClose,
    selectedLabel,
    handleCallback,
    date,
  } = props;

  const [title, setTitle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    if (selectedLabel?.includes("เวลาว่าง") === true) {
      setTitle(selectedLabel);
    }
  }, [selectedLabel]);

  const dict = {
    ครูเติม: "term",
    เวลาว่างครูเติม: "term",
    ครูมีน: "meen",
    เวลาว่างครูมีน: "meen",
    ครูกิต: "kit",
    เวลาว่างครูกิต: "kit",
    ครูวี: "wee",
    เวลาว่างครูวี: "wee",
    ครูพี: "pea",
    เวลาว่างครูพี: "pea",
    ครูบิ๊ก: "big",
    เวลาว่างครูบิ๊ก: "big",
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const eventObject = {
      owner: `admin ${dict[selectedLabel]}`,
      title: selectedLabel,
      date: date,
      name: title,
      start: start,
      end: end,
    };

    axios
      .post(
        `https://data.galileo-tutor.com/saved/create-save`,
        eventObject
      )
      .then((res) => console.log(res.data));

    setTitle("");
    setStart("");
    setEnd("");
    handleCallback(true);

    handleClose();
  };

  function formatDate(dateString) {
    const [day, month, year] = dateString.split("/");
    const date = new Date(`${month}/${day}/${year}`);
    const options = { weekday: "long" };
    return date.toLocaleDateString("th-TH", options);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 mt-20 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative max-w-screen-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle transition-all">
                {/* Main Content */}

                <div className="bg-white rounded-2xl">
                  <header className="bg-gray-100 px-4 py-2 flex justify-between rounded-t-2xl items-center">
                    <span className="material-icons-outlined text-gray-400">
                      drag_handle
                    </span>

                    <div>
                      {/* <span
                          className=" text-white bg-red-500 rounded-md px-1  cursor-pointer"
                          onClick={deleteEvent}
                        >
                          ลบ
                        </span> */}
                      <button>
                        <span
                          className="material-icons-outlined text-gray-400"
                          onClick={handleClose}
                        >
                          close
                        </span>
                      </button>
                    </div>
                  </header>
                  <div className="p-3">
                    <div className="grid grid-cols-1/5 items-center gap-y-7">
                      {selectedLabel?.includes("เวลาว่าง") ? (
                        <>
                          <span className="material-icons-outlined text-gray-400 text-center hidden">
                            person_outline
                          </span>
                          <p className="ml-3 hidden">
                            <input
                              type="text"
                              name="title"
                              placeholder="ชื่อนักเรียน"
                              value={title}
                              required
                              className="pt-3 border-0 text-gray-900 text-xl font-semibold pb-2 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                            />
                            <br />
                          </p>
                        </>
                      ) : (
                        <>
                          <span className="material-icons-outlined text-gray-400 text-center ">
                            person_outline
                          </span>
                          <p className="ml-3 ">
                            <input
                              type="text"
                              name="title"
                              placeholder="ชื่อนักเรียน"
                              value={title}
                              onChange={(e) =>
                                setTitle(e.target.value)
                              }
                              required
                              className="pt-3 border-0 text-gray-900 text-xl font-semibold pb-2  rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                            />
                          </p>
                        </>
                      )}

                      <span className="material-icons-outlined text-gray-400 text-center">
                        schedule
                      </span>
                      <p className="ml-3">
                        เริ่มตั้งแต่&nbsp;
                        <input
                          type="time"
                          name="start"
                          value={start}
                          required
                          className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 ml-4 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                          onChange={(e) => setStart(e.target.value)}
                        />
                        <br />
                        จนถึง&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                          type="time"
                          name="end"
                          value={end}
                          required
                          className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 ml-4 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                          onChange={(e) => setEnd(e.target.value)}
                        />
                        <br />
                        <div className="mt-3">
                          <span className="mr-14">วัน</span> {formatDate(date)}
                        </div>
                      </p>
                    </div>
                  </div>
                  <footer className="flex justify-end border-t p-3 mt-5">
                    {title === "" ||
                    start === "" ||
                    end === "" ||
                    selectedLabel === "" ? (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-gray-400 ease-in-out duration-150 cursor-not-allowed px-6 py-2 rounded-lg text-white "
                        disabled
                      >
                        เพิ่มบันทึก
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="bg-gray-800 ease-in-out duration-150 hover:bg-gray-900 px-6 py-2 rounded-lg text-white "
                      >
                        เพิ่มบันทึก
                      </button>
                    )}
                  </footer>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
