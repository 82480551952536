import React, { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { useLocation } from "react-router-dom";

const labelsClasses = [
  "ครูเติม",
  "เวลาว่างครูเติม",
  "ครูมีน",
  "เวลาว่างครูมีน",
  "ครูกิต",
  "เวลาว่างครูกิต",
  "ครูวี",
  "เวลาว่างครูวี",
  "ครูพี",
  "เวลาว่างครูพี",
  "ครูบิ๊ก",
  "เวลาว่างครูบิ๊ก",
];

export default function EventModal() {
  const location = useLocation();

  const example = [
    {
      user: "admin",
      permission: [
        "ครูเติม",
        "ครูมีน",
        "ครูกิต",
        "ครูวี",
        "ครูพี",
        "ครูบิ๊ก",
      ],
    },
    {
      user: "term",
      permission: ["ครูเติม"],
    },
    {
      user: "meen",
      permission: ["ครูมีน"],
    },
    {
      user: "kit",
      permission: ["ครูกิต"],
    },
    {
      user: "wee",
      permission: ["ครูวี"],
    },
    {
      user: "pea",
      permission: ["ครูพี"],
    },
    {
      user: "big",
      permission: ["ครูบิ๊ก"],
    },
  ];

  const {
    setShowEventModal,
    daySelected,
    dispatchCalEvent,
    selectedEvent,
  } = useContext(GlobalContext);

  const [title, setTitle] = useState(
    selectedEvent ? selectedEvent.title : ""
  );
  const [start, setStart] = useState(
    selectedEvent ? selectedEvent.start : ""
  );
  const [end, setEnd] = useState(
    selectedEvent ? selectedEvent.end : ""
  );
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );

  setDescription()
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );

  function handleSubmit(e) {
    e.preventDefault();
    const calendarEvent = {
      title,
      start,
      end,
      description,
      label: selectedLabel,
      day: daySelected.valueOf(),
      id: selectedEvent ? selectedEvent.id : Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowEventModal(false);
  }
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex justify-center bg-gray-800 bg-opacity-50 items-center">
      <form className="bg-white rounded-2xl  shadow-xl ">
        <header className="bg-gray-100 px-4 py-2 flex justify-between rounded-t-2xl items-center">
          <span className="material-icons-outlined text-gray-400">
            drag_handle
          </span>
          <div>
            {selectedEvent && (
              <span
                onClick={() => {
                  dispatchCalEvent({
                    type: "delete",
                    payload: selectedEvent,
                  });
                  setShowEventModal(false);
                }}
                className="material-icons-outlined text-gray-400 cursor-pointer"
              >
                delete
              </span>
            )}
            <button onClick={() => setShowEventModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div className="p-3">
          <div className="grid grid-cols-1/5 items-center gap-y-7">
            <span className="material-icons-outlined text-gray-400 text-center">
              person_outline
            </span>
            <p className="ml-3">
              <input
                type="text"
                name="title"
                placeholder="ชื่อนักเรียน"
                value={
                  selectedLabel.includes("เวลาว่าง")
                    ? selectedLabel
                    : title
                }
                required
                className="pt-3 border-0 text-gray-900 text-xl font-semibold pb-2 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                onChange={(e) => setTitle(e.target.value)}
              />
              <br />
              (ถ้าเป็นเวลาว่างไม่จำเป็นต้องกรอกชื่อ)
            </p>

            <span className="material-icons-outlined text-gray-400 text-center">
              schedule
            </span>
            <p className="ml-3">
              เริ่มตั้งแต่&nbsp;
              <input
                type="time"
                name="start"
                value={start}
                required
                className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 ml-4 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                onChange={(e) => setStart(e.target.value)}
              />
              <br />
              จนถึง&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="time"
                name="end"
                value={end}
                required
                className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 ml-4 mb-2 rounded-xl bg-gray-100 focus:outline-none focus:ring-0 focus:border-blue-500"
                onChange={(e) => setEnd(e.target.value)}
              />
              <br />
              {daySelected.format("dddd, MMMM DD")}
            </p>

            {/* <span className="material-icons-outlined text-gray-400 text-center">
              segment
            </span>
            <input
              type="text"
              name="description"
              placeholder="Add a description"
              value={description}
              required
              className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange={(e) => setDescription(e.target.value)}
            /> */}
            <span className="material-icons-outlined text-gray-400 text-center">
              bookmark_border
            </span>
            <div
              className={
                location.state.id === "admin"
                  ? `grid grid-cols-4 gap-x-3 gap-y-3`
                  : `grid grid-cols-3 gap-x-3 gap-y-3`
                  
              }
            >
              {example.map((item, index) => (
                <>
                  {item.user === location.state.id ? (
                    <>
                      {item.permission.map((per, idx) => (
                        <>
                          {labelsClasses.map((lblClass, i) => (
                            <>
                              {lblClass.includes(per) ? (
                                <div
                                  className="text-center cursor-pointer bg-gray-100 py-4 rounded-xl"
                                  onClick={() =>
                                    setSelectedLabel(lblClass)
                                  }
                                >
                                  <div className="flex items-center justify-center">
                                    <span
                                      key={i}
                                      onClick={() =>
                                        setSelectedLabel(lblClass)
                                      }
                                      className={`bg-${lblClass}-500 w-6 h-6 rounded-full  flex items-center justify-center cursor-pointer`}
                                    >
                                      {selectedLabel === lblClass && (
                                        <span className="material-icons-outlined text-white text-sm">
                                          check
                                        </span>
                                      )}
                                    </span>
                                  </div>

                                  <p className="text-sm mt-1">
                                    {lblClass}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ))}
                        </>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <footer className="flex justify-end border-t p-3 mt-5">
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-blue-500 ease-in-out duration-150 hover:bg-blue-600 px-6 py-2 rounded-lg text-white"
          >
            Save
          </button>
        </footer>
      </form>
    </div>
  );
}
