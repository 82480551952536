import axios from "axios";
import React, { useEffect, useState } from "react";

import * as Realm from "realm-web";
import EditBlogModal from "./components/EditBlogModal";
import CreateBlogModal from "./components/CreateBlogModal";
import BlogModal from "./components/BlogModal";
const app = new Realm.App({ id: "application-0-eligt" });

export default function Blog() {
  const [blog, setBlog] = useState([]);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [type, setType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState({});

  const [user, setUser] = useState();
  const [changeStream, setChangeStream] = useState([]);

  useEffect(() => {
    const login = async () => {
      // Authenticate anonymously
      const user = await app.logIn(Realm.Credentials.anonymous());
      setUser(user); // Connect to the database

      const mongodb = app.currentUser.mongoClient("mongodb-atlas");
      const collection = mongodb.db("test").collection("blog"); // Everytime a change happens in the stream, add it to the list of events

      for await (const change of collection.watch()) {
        setChangeStream((events) => [...events, change]);
      }
    };
    login();
  }, []);

  useEffect(() => {
    axios.get("https://data.galileo-tutor.com/blog").then((res) => {
      setBlog([...res.data].reverse());
      console.log("fetched");
      // console.log(data);
    });
  }, [changeStream]);

  function deleteBlog(id) {
    axios
      .delete("https://data.galileo-tutor.com/blog/delete-blog/" + id)
      .then((res) => {
        console.log("Save successfully deleted!");
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(id);
  }

  return (
    <>
     
      <div className="p-36">
        <h1 className="text-6xl font-extrabold text-gray-800">จัดการ Blogs</h1>
        <button
          className="sc mx-2 my-4 rounded bg-gray-800 px-3  py-2 text-white duration-150 ease-in-out hover:bg-gray-900"
          onClick={() => {
            setOpenCreateDialog(true);
          }}
        >
          เพิ่มบทความ
        </button>

        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 pt-10 sm:grid-cols-2  sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {blog.map((post, idx) => (
            <div>
              <article
                key={idx}
                className=" cursor-pointer "
                onClick={() => {
                  setSelectedItem(post);
                  setType("blog");
                  setOpenDialog(true);
                }}
              >
                <img
                  src={`https://drive.google.com/uc?export=view&id=${post.thumbnail}`}
                  className="mb-5 rounded-2xl"
                />
                <div className=" gap-x-4 text-xs">
                  <p className="text-gray-500">{post.date}</p>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-3xl  font-semibold leading-tight text-gray-900 group-hover:text-gray-600">
                    {post.title}
                  </h3>
                  <p className=" mt-5  text-xl text-gray-600">
                    {post.description}
                  </p>
                </div>
              </article>
              <button
                className="sc my-4 mr-2 rounded border border-gray-800 px-3  py-2 duration-150 ease-in-out hover:bg-gray-900 hover:text-white"
                onClick={() => {
                  setOpenEditDialog(true);
                  setSelectedBlog(post);
                }}
              >
                แก้ไข
              </button>
              <button
                className="sc mx-2 my-4 rounded border border-gray-800 px-3  py-2 duration-150 ease-in-out hover:bg-gray-900 hover:text-white"
                onClick={() => {
                  deleteBlog(post._id);
                }}
              >
                ลบ
              </button>
            </div>
          ))}
        </div>

        <BlogModal
          isOpen={openDialog}
          selectedItem={selectedItem}
          type={type}
          handleClose={() => {
            setOpenDialog(false);
            setSelectedItem({});
          }}
        />

        <CreateBlogModal
          isOpen={openCreateDialog}
          // changeStream={changeStream}
          handleClose={() => {
            setOpenCreateDialog(false);
          }}
        />
        <EditBlogModal
          isOpen={openEditDialog}
          selectedBlog={selectedBlog}
          // changeStream={changeStream}
          handleClose={() => {
            setOpenEditDialog(false);
            setSelectedBlog({});
          }}
        />
      </div>
    </>
  );
}
