import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Schedule from "./Schedule";
import Login from "./Login";
import Class from "./Class";
import Room from "./Room";
import Blog from "./Blog";



export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="/schedule" element={<Schedule />} />
      <Route path="/class" element={<Class />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/room" element={<Room />} />
    </Routes>
  );
}
